import React, { useState, forwardRef, useCallback, memo } from 'react';

import classy from '@core/utils/classy';

import './style.scss';

export interface SelectOptionProps {
  children?: SelectOptionProps[];
  disabled?: boolean;
  label: string;
  path?: string;
  value: number | string;
}

function Option({ opt }: { opt: SelectOptionProps }) {
  return (
    <option key={opt.label} data-url={opt.path ? opt.path : ''} disabled={opt.disabled} value={opt.value}>
      {opt.label}
    </option>
  );
}

export interface SelectProps extends Omit<React.SelectHTMLAttributes<HTMLSelectElement>, 'size'> {
  className?: string;
  defaultValue?: string;
  isInvalid?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  options?: SelectOptionProps[];
  size?: 'lg' | 'md' | 'sm' | 'xs';
  transparent?: boolean;
}

const Select = memo(
  forwardRef<HTMLSelectElement, SelectProps>(
    (
      {
        className,
        defaultValue,
        isInvalid,
        multiple,
        onChange,
        transparent,
        options,
        size = 'md',
        ...attrs
      }: SelectProps,
      forwardedRef,
    ) => {
      const [selectedOption, setSelectedOption] = useState(defaultValue || '');

      const changeHandler = useCallback<React.ChangeEventHandler<HTMLSelectElement>>(
        e => {
          setSelectedOption(e.target.value);
          onChange?.(e);
        },
        [onChange],
      );

      return (
        <select
          ref={forwardedRef}
          className={classy(
            'Select',
            `Select_${size}`,
            multiple && 'Select_multiple',
            isInvalid && 'Select_invalid',
            transparent && 'Select_transparent',
            className,
          )}
          multiple={multiple}
          onChange={changeHandler}
          value={selectedOption}
          {...attrs}
        >
          {options?.map((option, index) =>
            option.children?.length ? (
              <optgroup key={`select-optgroup-${option.label}-${index}`} label={option.label}>
                <Option key={`optgroup-option-${option.label}-${index}`} opt={option} />
                {option.children.map((child, childIndex) => (
                  <Option key={`optgroup-option-child-${child.label}-${index}-${childIndex}`} opt={child} />
                ))}
              </optgroup>
            ) : (
              <Option key={`select-option-${option.label}-${index}`} opt={option} />
            ),
          )}
        </select>
      );
    },
  ),
);

export default Select;
